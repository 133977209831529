import {Component, NgModule, OnInit} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, ParamMap } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'dexam';
  constructor(private route: ActivatedRoute, private _route: Router) {
    this.rout = _route;
    this.rous = route;
    const id: Observable<string> = route.params.pipe(map(p => p.id));
    const url: Observable<string> = route.url.pipe(map(segments => segments.join('')));
    // route.data includes both `data` and `resolve`
    // const user = route.data.pipe(map(d => d.user));
  }
  rout: Router;
  rous: ActivatedRoute;

  ngOnInit() {

    this.rout.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  // onActivate(event) {
  //   window.scroll(0, 0);
  // }
}
